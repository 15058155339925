import { SignIn } from '@clerk/nextjs'
import AuthLayout from '@layouts/AuthLayout'

const SignInPage = (props) => {
	return <SignIn signUpUrl="/sign-up" />
}

export default SignInPage

SignInPage.layoutProps = {
	Layout: AuthLayout,
	title: 'Sign In',
}
