export default function AuthLayout({ children }): JSX.Element {
	return (
		<div className="min-h-screen">
			<div className="flex min-h-screen">
				<div className="flex flex-col items-center justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-2 lg:px-12">
					<div className="w-full max-w-sm mt-8">{children}</div>
				</div>
				<div className="relative flex-1 hidden w-0 lg:block bg-blueGray-700 dark:bg-primary-900" />
			</div>
		</div>
	)
}
